.gpt3__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--color-footer);
  margin-top: auto; /* Pushes the footer to the bottom */
}

.gpt3__footer-heading {
  width: 100%;
  text-align: center;

  margin-bottom: 3rem;
}

.gpt3__footer-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.gpt3__footer-button {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  border: 1px solid #fff;
  text-align: center;

  margin-bottom: 6rem;
  cursor: pointer;
}

.gpt3__footer-button p {
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 24px; 
  text-align: center;
  color: #fff;
  word-spacing: 2px;
}

.gpt3__footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  text-align: left;
}

.gpt3__footer-content div {
    width: 250px;
    margin: 1rem;
}

.gpt3__footer-content__logo {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-content__logo img {
  /* width: 160px; */
  height: 64px;

  margin-bottom: 1rem;
}

.gpt3__footer-content__logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.gpt3__footer-content__div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;  
}

.gpt3__footer-content__div h4 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #fff;

  margin-bottom: 1.5rem;
}

.gpt3__footer-content__div p {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 16px; 
  text-align: left;
  color: #fff;

  margin-bottom: 0.75rem;  
  padding: 0.5rem 0;
}

.gpt3__footer-content__div a:hover {
  border-left: 0.3rem solid white;
  padding-left: 0.5rem;
}

.gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.gpt3__footer-copyright p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 16px; 
  color: #fff;
}

@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }

    .gpt3__footer-content__logo img {
      /* width: 160px; */
      height: 56px;
    }

    .gpt3__footer-content {
        display: none;
    }

    .gpt3__footer-copyright {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-content div {
        margin: 1rem 0;
    }

    .gpt3__footer-content__logo img {
      /* width: 160px; */
      height: 48px;
    }

    .gpt3__footer-button p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}