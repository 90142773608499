.ride__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ride__details-heading {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ride__details-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -0.04em;
  text-align: center;
}

.ride__details-heading p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  text-align: center;

  margin: 0.5rem 0;
}

.ride__details-content { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 330px;
}

.ride__details-content__wrapper {
  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: center;
}

.ride__details-content__wrapper label {
  flex: 1;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 0 0.25rem;

  margin: 0 0.5rem;
  color: #fff;
}

.ride__details-content__wrapper label input{
  flex: 1;
  width: 100%;
  height: 40px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;

  border: 0.05px solid #aaaaaa;
  margin: 0.5rem 0;
  border-radius: 5px;
  text-transform: capitalize;
}
.ride__details-content div {
  width: 100%;
}

.ride__details-content img{ 
  justify-content: center;
  align-items: center;
  max-height: 150px; 
  max-width: 250px;
  object-fit: contain;

  margin-bottom: 1rem;
}

.ride__details-content button {
  flex: 0.4;
  /* width: 100%; */
  min-width: 2rem;
  min-height: 40px;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  background-color: #fff;
  border: 2px solid #aaaaaa;
  padding: 0 1rem;
  color: var(--color-footer);
  cursor: pointer;
  outline: none;

  margin: 1rem 0.5rem 0;
  border-radius: 5px;
}

.ride__details-content__all-btn {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}

.ride__details-content__all-btn .delete-btn {
  background: #FF4820;
  border: 2px solid #FF4820;
}

.ride__details-content__all-btn .book-btn {
  background: #07a85d;
  border: 2px solid #07a85d;
}

.ride__details-passenger__heading {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ride__details-passenger__heading h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.04em;
  text-align: left;

  margin: 1rem 0;
}

.ride__details-passenger__content { 
  width: 100%;
  max-width: 280px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
}

.ride__details-passenger__content p,
.ride__details-passenger__content a { 
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  text-transform: capitalize;

  margin-left: 0.25rem 0;
  padding: 0.25rem 0.5rem;
}

.ride__details-passenger__content a { 
  color: unset;
  text-decoration: none;
}

.ride__details-passenger__content div { 
  flex: 0.3;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
}

@media screen and (max-width: 650px) {
  .ride__details-heading h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .ride__details-heading p {
    font-size: 16px;
    line-height: 24px;
  }

  .ride__details-content__wrapper label {
    font-size: 12px;
    line-height: 16px;  
  }

  .ride__details-content__wrapper label input{
    font-size: 16px;
    line-height: 24px;  
  }

  .ride__details-passenger__heading h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .ride__details-passenger__content p,
  .ride__details-passenger__content a {
    font-size: 16px;
    line-height: 24px;
  }

  .ride__details-content button {
    font-size: 16px;
    line-height: 24px;  
  }
}

@media screen and (max-width: 490px) {
  .ride__details-heading h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .ride__details-heading p {
    font-size: 14px;
    line-height: 24px;
  }

  .ride__details-passenger__heading h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .ride__details-passenger__content p,
  .ride__details-passenger__content a {
    font-size: 14px;
    line-height: 24px;
  }

  .ride__details-content button {
    font-size: 14px;
    line-height: 24px;    
  }
}