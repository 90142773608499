.profile__form {
  display: flex;
  flex-direction: column;
}

.profile__form-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-bottom: 5rem;
}

.profile__form-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -0.04em;
  text-align: center;

  margin-bottom: 2rem;
}

.profile__form-content button {
  flex: 0.5;
  width: 100%;
  min-width: 2rem;
  min-height: 40px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #FF4820;
  border: 2px solid #FF4820;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;

  border-radius: 5px;
  margin-top: 1rem;
}

.profile__form-content p {
  font-family: var(--font-family);
  /* font-weight: 400; */
  font-size: 20px;
  line-height: 28px;
  /* color: var(--color-text); */
  text-align: center;

  /* margin: 0.5rem 0; */
}

.profile__form-content a {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.profile__form-content__field {
  max-width: 330px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  flex-wrap: wrap;

  width: 100%;
  margin: 0 1rem;
  padding: 0 0.5rem;
}

.profile__form-content__field img{ 
  /* justify-content: center; */
  align-items: center;
  max-height: 150px; 
  /* max-width: 250px; */
  object-fit: contain;

  margin-bottom: 1rem;
}

.profile__form-content__field-div {
  max-width: 330px;
  display: flex;
  flex-direction: row;
  align-items: left;

  padding: 0 0.5rem;
  /* border-radius: 0.25rem;
  border-bottom: 0.1rem solid #ffffff5e; */
  
  margin: 0.5rem 0;
}

.profile__form-content__field-div h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-text);
  /* color: #fff; */
}

.profile__form-content__field-div p {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  text-transform: capitalize;

  background: var(--color-footer);
  margin-left: 0.5rem;
}

p.lowercase-letter {
  text-transform: lowercase;  
}

.login__form-content__input .green-btn {
  background: #07a85d;
  border: 2px solid #07a85d;
}

@media screen and (max-width: 1050px) {
  .profile__form {
    flex-direction: column;
  }

  .profile__form-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .profile__form-content h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .profile__form-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .profile__form-content button {
      font-size: 16px;
      line-height: 24px;
  }

  .profile__form-content__field-div h3,
  .profile__form-content__field-div p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .profile__form-content h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .profile__form-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .profile__form-content button {
    font-size: 14px;
    line-height: 24px;
  }

  .profile__form-content__field {
    flex-direction: column;
  }

  .profile__form-content__field h3,
  .profile__form-content__field p {
    font-size: 14px;
    line-height: 24px;
  }
}