.field__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: 250px;
}

.field__content-div {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0.5rem 0;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border-bottom: 0.1rem solid #ffffff5e;
}

.field__content-div h3 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-text);
  /* color: #fff; */
}

.field__content-div p {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  text-transform: capitalize;

  margin-left: 0.5rem;
}

@media screen and (max-width: 650px) {
  .field__content-div h3,
  .field__content-div p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .field__content {
    flex-direction: column;

    width: 200px;
  }

  .field__content h3,
  .field__content p {
    font-size: 14px;
    line-height: 24px;
  }
}