.gpt3__feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.feed__content {    
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* margin-right: 5rem; */
}

.feed__content-heading {
  text-align: center;
}

.feed__content-heading h1 {
  width: 100%;;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -0.04em;
}

.feed__content-heading p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  text-align: center;

  margin: 0.5rem 0;
}

.feed__content-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 2rem;
}

.feed__content-form input {
  flex: 1;
  
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000;

  max-width: 130px;
  margin: 0 0.5rem;
  padding: 0.25rem 0.5rem;
  
  -webkit-text-size-adjust: 100%; /* Prevents zooming on iOS */
  -moz-text-size-adjust: 100%; /* Prevents zooming on iOS */
  -ms-text-size-adjust: 100%; /* Prevents zooming on iOS */
  text-size-adjust: 100%;
}

.feed__content-form button {
  margin: 0 0.5rem;
  padding: 0.25rem 0.5rem;
  
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  
  background: #FF4820;
  border: 2px solid #FF4820;
  border-radius: 5px;
}

.feed__content-rides {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin-top: 1rem;
}

.feed__content-rides__error {
  color: #FF4820;
}

.feed__content-rides__post { 
  width: 320px;
  display: flex;
  flex-direction: row;
  border: 0.5px solid #fff;
  border-radius: 10px;
  background: var(--color-footer);

  margin: 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
}

.feed__content-rides__post-left {
  flex-basis: 70%;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.feed__content-rides__post-right {
  flex-basis: 30%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.feed__content-rides__post h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  text-transform: capitalize;
}

.feed__content-rides__post p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-text);
  text-transform: capitalize;
}

.feed__content-rides__post span {
  color: var(--color-text);
  text-transform: lowercase;

  padding: 0 0.25rem;
}

.feed__content-rides__post svg{
  color: #ffffff5e;
  width: 1rem;
  
  margin: 0 0.5rem;
}

.feed__content-rides__post-right img { 
  display: block;
  height: 56px; 
  width: 56px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 0.5rem;

  background-color: #fff;
}

.feed__content-rides__post-right p {
  font-weight: 700;
  color: #fff;
}

@media screen and (max-width: 1050px) {
  .feed__content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .feed__content-heading h1 {
    font-size: 36px;
    line-height: 44px;
    max-width: 390px;
  }

  .feed__content-heading p {
    font-size: 16px;
    line-height: 24px;
  }  

  .feed__content-rides__post h2 {
    font-size: 18px;
    line-height: 32px;
  }  

  .feed__content-rides__post p {
    font-size: 16px;
    line-height: 24px;
  } 
}

@media screen and (max-width: 490px) {
  .feed__content-heading h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .feed__content-heading p {
    font-size: 14px;
    line-height: 24px;
  } 

  .feed__content-rides__post h2 {
    font-size: 16px;
    line-height: 28px;
  } 

  .feed__content-rides__post p {
    font-size: 14px;
    line-height: 24px;
  } 
}