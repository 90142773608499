.login__form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
/**********************************/
/* Slide Controls */
.login__form-content__input-slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 1.5rem 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.login__form-content__input-slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}

.login__form-content__input-slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 15px;
  /* background: -webkit-linear-gradient(left, #003366, #004080, #0059b3, #0073e6); */
  background-color: var(--color-blog);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input[type="radio"]{
  display: none;
}

.shift-right-slider {
  transform: translateX(100%);
}

.login__form-content__input-container {
  display: flex;
  justify-content: space-between;
  width: 200%;
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.login__form-content__heading-container__wrapper,
.login__form-content__input-container__wrapper {
  width: 50%;
  padding: 0 20px 1rem;
}

.shift-right {
  transform: translateX(-50%);
}

.hide {
  display: none;
}

/**************************/
.login__form-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* margin-right: 5rem; */

  
  overflow: hidden;
  max-width: 440px;
}

.login__form-content__heading {
  text-align: center;
}
.login__form-content__heading-container {
  display: flex;
  justify-content: center;
  width: 200%;
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.slide-heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: #fff;
}

.shift-right {
  transform: translateX(-50%);
}

.login__form-content h1 {
  width: 100%;;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -0.04em;
}

.login__form-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  text-align: center;

  margin: 0.5rem 0;
}

.login__form-content__input-container__wrapper div {
  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: center;
  flex-basis: 50%;
}

.login__form-content__input-container__wrapper div label {
  flex-basis: 50%;
}

.login__form-content__input-container__wrapper p {
  font-size: 16px;
  line-height: 28px;
}

.login__form-content__input {
  /* width: 100%; */
  max-width: 330px;
  /* margin: 2rem 1rem; */

  /* display: flex; */
  /* flex-direction: column; */
}

.login__form-content__input input,
.login__form-content__input select {
  flex: 1;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;

  border: 0.05px solid #aaaaaa;
  margin: 0.5rem 0;
  border-radius: 5px;
}

.login__form-content__input label {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 0 0.25rem;

  margin: 0;
  color: #fff;
}

.login__form-content__input button {
  flex: 0.4;
  width: 100%;
  min-width: 2rem;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #FF4820;
  border: none;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;

  margin: 0.5rem 0;
  border-radius: 5px;

  
  /* width: 100%; */
  /* padding: 15px; */
  /* border: none; */
  /* background-color: #0073e6; */
  /* color: white; */
  /* cursor: pointer; */
  /* border-radius: 5px; */
}
/* 
.login__form-content__input button:hover {
  background-color: #0059b3; 
}
*/

.login__form-content__input-google__logo  {
  /* flex: 0.4; */
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 100%; */
  /* min-width: 2rem; */
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  cursor: pointer;
  /* outline: none; */

  margin: 0.5rem 0;
  border-radius: 5px;
}

.login__form-content__input-google__logo img  {
  width: auto;
  height: 30px;

  margin-right: 1rem;
}

.login__form-content__input a {
  color: #fff;
  /* border-bottom: 1px solid #fff; */
}

.login__form-content__input-separator {
  display: flex;
  align-items: center;
  --text-divider-gap: 1rem;

  margin: 0.5rem 0;
}

.login__form-content__input-separator::before,
.login__form-content__input-separator::after {
  content: '';
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}

.login__form-content__input-separator::before {
  margin-right: var(--text-divider-gap);
}

.login__form-content__input-separator::after {
  margin-left: var(--text-divider-gap);
}

@media screen and (max-width: 1050px) {
  .login__form {
    flex-direction: column;
  }

  .login__form-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .login__form-content h1 {
    font-size: 36px;
    line-height: 44px;
    max-width: 390px;
  }

  .login__form-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .login__form-content__input label {
    font-size: 12px;
    line-height: 16px;
  }

  .login__form-content__input input,
  .login__form-content__input select,
  .login__form-content__input button,
  .login__form-content__input-google__logo  {
      min-height: 40px;
      font-size: 16px;
      line-height: 24px;
  }
  
  .login__form-content__input-google__logo img  {
    width: auto;
    height: 24px;

    margin-right: 0.75rem;
  }
}

@media screen and (max-width: 490px) {
  .login__form-content h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .login__form-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .login__form-content__input input,
  .login__form-content__input select,
  .login__form-content__input button,
  .login__form-content__input-google__logo  {
    font-size: 16px;
    line-height: 16px;
  }
}