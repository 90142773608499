.gpt3__faqs {
  display: flex;
  flex-direction: column;
}

.gpt3__faqs h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -0.04em;
  text-align: center;

  margin-bottom: 2rem;
}

.gpt3__faqs-container__item {
  border-bottom: 1px solid lightgrey;
  /* border-radius: 5px; */

  margin: 0 1rem;
}

.gpt3__faqs-container__item h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.04em;
  color: var(--color-text);
  text-align: left;

  margin: 1rem 0 0.5rem;
}

.gpt3__faqs-container__item p {
  font-family: var(--font-family);
  /* font-weight: 400; */
  font-size: 18px;
  line-height: 28px;
  color: #ffffffde;
  text-align: center;

  margin-bottom: 1rem;
}

.gpt3__faqs-container__item p {
  text-align: left;
}

/*
.gpt3__faqs-container__item p {
  color: #FF4820;
  text-align: center;
}
*/

.hide {
  display: none;
}

@media screen and (max-width: 1050px) {
}

@media screen and (max-width: 650px) {
  .gpt3__faqs h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .gpt3__faqs-container__item h2 {
    font-size: 16px;
    line-height: 24px;
  }

  .gpt3__faqs-container__item p {
    font-size: 14px;
    line-height: 24px;
  }
}