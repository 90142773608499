.gpt3__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem 6rem;
}

.gpt3__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gpt3__navbar-links_logo  {
  margin-right: 5rem;
}

.gpt3__navbar-links_logo img  {
  /* width: 160px; */
  height: 64px;
}

.gpt3__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.gpt3__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gpt3__navbar-links_container svg {
  color: #ffffff5e;
  width: 1rem;
  
  margin: 0 0.5rem;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background-color: #FF4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.gpt3__navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.gpt3__navbar-menu svg {
  cursor: pointer;
}

.gpt3__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  
  /* text-align: end; */
  background: var(--color-blog);
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 130px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
  z-index: 2;
}

.gpt3__navbar-menu_container p {
  margin: 1rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid lightgray;
}

.gpt3__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .gpt3__navbar-links_container {
      display: none;
  }

  .gpt3__navbar-menu {
      display: flex;
  }

  .gpt3__navbar-menu .gpt3__navbar-sign {
    display: none;
  }

  .gpt3__navbar-menu_container {
      width: 22vw;
  }

  .gpt3__navbar-menu_container-links {
    width: 100%;
  }

  .gpt3__navbar-menu_container-links svg {
    color: #ffffff5e;
    width: 1rem;
    
    margin: 0 0.5rem 0 0.5rem;
  }
}

@media screen and (max-width: 700px) {
  .gpt3__navbar {
      padding: 2rem 4rem;
  }

  .gpt3__navbar-links_logo img  {
    /* width: 160px; */
    height: 56px;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__navbar {
      padding: 2rem;
  }

  .gpt3__navbar-links_logo img  {
    /* width: 160px; */
    height: 48px;
  }
  
  .gpt3__navbar-sign {
      display: none;
  }

  .gpt3__navbar-menu .gpt3__navbar-sign {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .gpt3__navbar-menu_container {
      top: 20px;
      width: 85vw;
  }

  .gpt3__navbar-menu_container p {
    width: 100%;
    text-align: left;
  }

  .gpt3__navbar-menu_container button {
    padding: 0.5rem 0.5rem;
  }

  .gpt3__navbar-menu_container-links-sign {
      display: block;
  }
}