.gpt3__cta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 2rem;
  margin: 4rem;
  border-radius: 1rem;
  background: var(--gradient-bar);
}

.gpt3__cta-content p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  color: #0E0E0E;

  margin: 0 0 0.5rem 0;
}

.gpt3__cta-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 24px;
  line-height: 45px;
  color: #000;

  text-align: center;
}

.gpt3__cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.gpt3__cta-button button {
  background: #000;
  color:#fff;
  border-radius: 1rem;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
}

@media screen and (max-width: 650px) {
  .gpt3__cta {
    flex-direction: column;
  }

  .gpt3__cta-button {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 650px) {
  .gpt3__cta {
    margin: 3rem 2rem;
  }

  .gpt3__cta-content h3 {
    font-size: 18px;
    line-height: 32px;
  }
  
  .gpt3__cta-button button {
    font-size: 14px;
    line-height: 28px;
  }
}