.message__container {
  display: flex;
  flex-direction: row;
}

.message__container-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message__container-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -0.04em;
  text-align: center;
}

.message__container-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  text-align: center;

  margin: 0.5rem 0;
}

@media screen and (max-width: 1050px) {
  .message__container {
    flex-direction: column;
  }

  .message__container-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .message__container-content h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .message__container-content p {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .message__container-content h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .message__container-content p {
    font-size: 14px;
    line-height: 24px;
  }
}