.admin__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin__details-heading {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.admin__details-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -0.04em;
  text-align: center;

  margin-bottom: 2rem;
}

.admin__details-passenger__content { 
  /* width: 100%; */
  width: 280px;
  /*height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;*/
  /* align-items:center; */

  border: 0.5px solid gray;
  border-radius: 0.5rem;
  margin: 0.5rem 0px;
  padding: 0.5rem 0;
}

.admin__details-passenger__content p,
.admin__details-passenger__content a { 
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  text-transform: capitalize;

  margin-left: 0.25rem 0;
  padding: 0.25rem 0.5rem;
}

.admin__details-passenger__content a { 
  color: unset;
  text-decoration: none;
}

.admin__details-passenger__content div { 
  flex: 0.3;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.admin__details-passenger__content button {
  /* flex: 1; */
  /* width: 100%; */
  min-width: 2rem;
  max-width: 45%;
  min-height: 30px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #FF4820;
  border: 2px solid #FF4820;
  padding: 0 0.5rem;
  color: #fff;
  cursor: pointer;
  outline: none;

  border-radius: 5px;
  margin: 0 1rem;
}

.admin__details-passenger__content .unban-btn {
  background: #07a85d;
  border: 2px solid #07a85d;
}

@media screen and (max-width: 650px) {
  .admin__details-passenger__content p,
  .admin__details-passenger__content a {
    font-size: 16px;
    line-height: 24px;
  }

  .admin__details-passenger__content button {
    font-size: 16px;
    line-height: 24px;    
  }
}

@media screen and (max-width: 490px) {
  .admin__details-passenger__content p,
  .admin__details-passenger__content a {
    font-size: 14px;
    line-height: 24px;
  }

  .admin__details-passenger__content button {
    font-size: 14px;
    line-height: 24px;
  }
}