.listing__form {
  display: flex;
  flex-direction: column;
}

.listing__ride-container p {
  font-family: var(--font-family);
  /* font-weight: 400; */
  font-size: 20px;
  line-height: 28px;
  /* color: var(--color-text); */
  text-align: center;

  /* margin: 0.5rem 0; */
}

.listing__ride-container p {
  text-align: left;
}

/*
.listing__ride-container p {
  color: #FF4820;
  text-align: center;
}
*/

.listing__ride-container h1 {
  width: 100%;;
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: -0.04em;
  text-align: center;
  text-transform: capitalize;

  margin-bottom: 2rem;
}

.listing__ride-container__wrapper-slider__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.listing__ride-container__wrapper-slider__content-post { 
  width: 330px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #fff;
  border-radius: 10px;
  background: var(--color-footer);
  align-items: flex-start;
  justify-content: center;

  margin: 0.5rem 0.5rem;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
}

.listing__ride-container__wrapper-slider__content-post h2 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  text-transform: capitalize;
}

.listing__ride-container__wrapper-slider__content-post p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-text);
  text-transform: capitalize;
}

.listing__ride-container__wrapper-slider__content-post svg{
  color: #ffffff5e;
  width: 1rem;
  
  margin: 0 0.5rem;
}

.listing__ride-container__slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  max-width: 350px;
  overflow: hidden;
  margin: 30px auto 10px auto;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.listing__ride-container__slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}

.listing__ride-container__slide-controls .shift-right-slider {
  transform: translateX(100%);
}

.listing__ride-container__slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 15px;
  /* background: -webkit-linear-gradient(left, #003366, #004080, #0059b3, #0073e6); */
  background-color: var(--color-blog);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.listing__ride-container__slide-controls label {
	color: #fff;
	font-family: var(--font-family);
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin: 0;
	padding: 0 .25rem;
}

.listing__ride-container__wrapper {
  display: flex;
  justify-content: space-between;
  width: 220%;
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.listing__ride-container__wrapper.shift-right {
  transform: translateX(-55%);
}

.listing__ride-container__wrapper-slider {
  width: 45%;
  padding: 20px;
}

.login__form-content__input .green-btn {
  background: #07a85d;
  border: 2px solid #07a85d;
}

@media screen and (max-width: 1050px) {
  .listing__form {
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .listing__ride-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    /* margin-right: 5rem; */
      
    overflow: hidden;
    max-width: 440px;
  }

  .listing__ride-container p {
    font-size: 16px;
    line-height: 24px;
  }

  .listing__ride-container h1 {
    font-size: 36px;
    line-height: 44px;
    max-width: 390px;
  }

  .listing__ride-container__wrapper-slider__content-post h2 {
    font-size: 18px;
    line-height: 32px;
  }
  
  .listing__ride-container__wrapper-slider__content-post p {
    font-size: 16px;
    line-height: 24px;
  }

  .listing__ride-container__forms {
    max-width: 330px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 490px) {
  .listing__ride-container p {
    font-size: 14px;
    line-height: 24px;
  }

  .listing__ride-container h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .listing__ride-container__wrapper-slider__content-post h2 {
    font-size: 16px;
    line-height: 28px;
  }
  
  .listing__ride-container__wrapper-slider__content-post p {
    font-size: 14px;
    line-height: 24px;
  }
}